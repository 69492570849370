<template>
  <div class="showpopou">
    <div v-if="videoUrl">
      <video id="media" @loadeddata="setVideoPoster($event)" autoplay="autoplay" style="width: 100%;height: 100%;" class="media"
        controls :src="videoUrl">
        浏览器不支持</video>
    </div>
    <img v-else :src="imgUrl" alt="">
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive, toRefs, nextTick } from "vue";
import { useRoute } from "vue-router";
export default {
  setup() {
    const state = reactive({
      showpopou: false,
      videoUrl: '',
      imgUrl: '',
      heightVideo: 700,
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $http: http, $router: router, $tool: tool, $store: store } = ctx;
    //调用useRoute,获取页面携带的参数
    const route = useRoute();
    // 方法
    const methods = {
      checkFile(fileValue, type) {
        console.log("fileValue==" + fileValue);
        var index = fileValue.indexOf("."); //（考虑严谨用lastIndexOf(".")得到）得到"."在第几位
        var fileValueSuffix = fileValue.substring(index); //截断"."之前的，得到后缀
        if (type == 'video') {
          if (!/(.*)\.(mp4|rmvb|avi|ts)$/.test(fileValueSuffix)) { //根据后缀，判断是否符合视频格式
            return false;
          }
        }
        if (type == 'image') {
          if (!/(.*)\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(fileValueSuffix)) { //根据后缀，判断是否符合图片格式
            return false;
          }
        }

        return true;
      },
      setVideoPoster(event) {
        let video = event.target;
        let canvas = document.createElement("canvas"); // 创建 canvas
        const ctx = canvas.getContext("2d");
        video.currentTime = 1; // 第一帧
        video.oncanplay = () => {
          canvas.width = video.clientWidth; // 获取视频宽度
          canvas.height = video.clientHeight; //获取视频高度
          var img = new Image(); // 这里使用img是为了解决视频跨域 canvas.toDataURL会提示错误的问题
          img.onload = function () {
            // canvas绘图
            ctx.drawImage(video, 0, 0, video.clientWidth, video.clientHeight);
            // 转换成base64形式并设置封面
            video.poster = canvas.toDataURL("image/jpeg", 1); // 截取后的视频封面
          }
        }
      },
    };
    onMounted(() => {
      if (methods.checkFile(route.query.RECHARGE_GUIDANCE_URL, 'video')) {
        state.videoUrl = route.query.RECHARGE_GUIDANCE_URL
      } else {
        state.imgUrl = route.query.RECHARGE_GUIDANCE_URL
      }
      ctx.heightVideo = document.body.clientHeight - 150
    });
    return {
      ...toRefs(state),
      ...methods,
      tool
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";

.showpopou {
  height: 100%;
  div {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
  }
}
</style>
